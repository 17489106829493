<template>
  <div class="policy">
    <div class="policy-box">
        <h1 class="title">果老网 隐私条款</h1>
        <p class="p1">果老网（以下或称“我们”）注重保护用户（以下简称“您”）个人信息及个人隐私。我们将按照法律法规要求，提高安全保护措施，尽力保护您的个人信息安全，鉴此，我们制定本隐私政策（以下称“本政策”）。</p>
        <p class="p1">本隐私政策解释了您个人信息收集（以下或称“信息”）和使用的有关情况，本隐私政策适用于果老网向您提供的所有相关产品或服务（包括但不限于电子商务、网络资讯、网络社交、互联网金融服务等，以下称“果老网服务”或“服务”），
          如我们其他产品或服务中使用了相关隐私政策的，本政策同样适用于该部分产品或服务。</p>
        <p class="p1">在使用果老网服务时，请您务必仔细阅读并熟悉理解本政策，并在确认完全同意后使用果老网相关产品或服务。如果您不同意本隐私政策的任何内容，您应立即停止使用果老网服务。
          当您使用果老网提供的任一服务时，即视同您已完全理解并同意本隐私政策，我们将按照本政策来合法使用和保护您的个人信息。</p>
        <p class="p3">1. 您个人信息的收集</p>
        <p class="p2">我们收集信息是为了向您提供更好以及更个性化的服务，并努力提高您的用户体验。我们收集信息的种类如下：</p>
        <p class="p2">1.1您向我们提供的信息</p>
        <p class="p2">当您注册果老网账户及您在使用相关果老网服务时填写、提交及/或其他任何方式提供的信息，包括但不限于您的企业名称、统一社会信用代码、企业注册地址、姓名、性别、出生年月日、身份证号码、护照姓、护照名、护照号码、电话号码、电子邮箱、收货地址、果老网账号、银行卡信息及相关附加信息（如您地址中的所在的省份和城市、邮政编码等）。您可以选择不提供某一或某些信息，但是这样可能使您无法使用果老网的许多特色服务。
          请您理解，我们使用您提供的信息是为了回应您的要求，为您在果老网享受服务提供便利，完善果老网网站以及与您进行信息沟通。另外，我们可能会将您所提供的信息与您的果老网账户关联，用以识别您的身份。</p>
        <p class="p2">1.2我们在您使用服务过程中获取的信息</p>
        <p class="p2">为了提高服务质量和用户体验，我们会留存您使用服务以及使用方式的相关信息，这类信息包括：</p>
        <p class="p2">（1）您的浏览器和计算机上的信息。在您访问果老网网站或使用果老网服务时，果老网系统自动接收并记录的您的浏览器和计算机上的信息
          （包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据）。</p>
        <p class="p2">（2）您的位置信息。当您下载或使用果老网、其关联方及合作伙伴开发的应用程序（例如果老网APP），或访问移动网页使用果老网服务时，
          果老网可能会读取您的位置（大多数移动设备将允许您关闭定位服务，具体建议您联系您的移动设备的服务商或生产商）。</p>
        <p class="p2">（3）您的行为或交易信息。果老网可能会记录您访问果老网或使用果老网服务时所进行的操作以及您在果老网网站上进行交易的相关信息。</p>
        <p class="p2">除上述信息外，我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与我们的客服团队联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与果老网的关联方、果老网合作伙伴之间互动时我们获得的相关信息。同时，
          为提高您使用果老网提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的链接（“URL”）。</p>
        <p class="p2">1.3来自第三方的信息</p>
        <p class="p2">指在您注册果老网账户和使用服务过程中，您授权的果老网可向果老网的关联方、合作伙伴所收集的相关信息，以及您授权的果老网的关联方、合作伙伴可向果老网分享相关的信息。这些信息包括但不限于您的身份信息、行为信息、交易信息、设备信息等，果老网会将此类信息汇总，用于帮助果老网向您提供更好以及更加个性化的服务或更好的预防互联网欺诈。
您了解并同意，以下信息不适用本隐私政策：</p>
        <p class="p2">（1）您在使用果老网提供的搜索服务时输入的关键字信息；</p>
        <p class="p2">（2）信用评价、违反法律法规规定或违反果老网平台规则行为及果老网已对您采取的措施；</p>
        <p class="p2">（3）应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息；</p>
        <p class="p2">（4）所收集的信息是您自行向社会公众公开的；</p>
        <p class="p2">（5）从合法公开披露的信息中收集的个人信息的；</p>
        <p class="p2">（6）其他您与果老网或果老网的关联方所签署的协议（包括在线签署的电子协议，例如《果老网用户注册协议》）以及果老网平台规则中明确约定或提示您不适用本隐私政策的与您有关的信息；</p>
        <p class="p2">（7）法律法规规定的其他情形。</p>
        <p class="p3">2. 我们对您个人信息的管理和使用</p>
         <p class="p2">为向您提供服务、提升我们的服务质量以及优化您的服务体验，我们会在符合法律规定或根据您授权的情况下使用您的个人信息，并主要用于下列用途：</p>
         <p class="p2">2.1向您提供您使用的各项服务，并维护、改进这些服务。</p>
         <p class="p2">2.2向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或在征得您同意的情况下与果老网的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息。如您不希望接收上述信息，可通过相应的退订功能进行退订。</p>
         <p class="p2">2.3我们可能使用您的个人信息以验证身份、预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联方协议、政策或规则的行为，以保护您、其他果老网用户，或我们或其关联方的合法权益。</p>
         <p class="p2">2.4我们可能会将来自某项服务的个人信息与来自其他服务所获得的信息结合起来，用于为了给您提供更加个性化的服务使用，例如为让您通过交易拥有更广泛的社交圈而使用、共享或披露您的信息。</p>
         <p class="p2">2.5我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</p>
         <p class="p2">2.6让您参与有关我们产品及服务的调查。</p>
         <p class="p2">2.7经您同意或授权的其他用途。</p>
         <p class="p3">3. 您个人信息的分享</p>
         <p class="p2">您的个人信息是我们为您提供服务的重要部分，我们会遵循法律规定对您的信息承担保密义务。除以下情形外，我们不会将您的个人信息披露给第三方：</p>
         <p class="p2">3.1征得您的同意或授权；</p>
         <p class="p2">3.2根据法律法规的规定或行政或司法机构的要求；</p>
         <p class="p2">3.3出于实现“我们对您个人信息的管理和使用”部分所述目的，或为履行我们在《果老网用户注册协议》或本隐私政策中的义务和行使我们的权利，
           向果老网的关联方、合作伙伴或代表果老网履行某项职能的第三方（例如代表我们发出推送通知的通讯服务商、处理银行卡的支付机构等）分享您的个人信息；</p>
        <p class="p2">3.4如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能产生的权利纠纷；</p>
        <p class="p2">3.5只有共享您的信息，才能提供您需要的服务，或处理您与他人的纠纷或争议；</p>
        <p class="p2">3.6您出现违反中国有关法律、法规规定或者违反您与果老网签署的相关协议（包括在线签署的电子协议）或违反相关果老网平台规则时需要向第三方披露的情形。</p>
        <p class="p2">3.7为维护果老网及其关联方或其他果老网用户的合法权益。</p>
        <p class="p2">随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。</p>
        <p class="p3">4. 您个人信息的安全</p>
        <p class="p2">果老网严格保护您的个人信息安全。我们使用各种制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、篡改、披露或破坏。如果您对我们的个人信息保护有任何疑问，请联系我们的客服。</p>
        <p class="p2">在通过果老网网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。
          请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即联络我们的客服，以便我们采取相应措施。</p>
        <p class="p3">5. 访问和更新您的个人信息</p>
        <p class="p2">您可以在“我的汇桔”页面的“账户设置”菜单中查阅您提交给果老网的所有个人信息，您也可通过上述途径更新除实名认证信息之外的其他个人信息（您的实名认证信息是您通过实名认证时使用的姓名和身份证信息）。</p>
        <p class="p3">6. Cookie及网络Beacon的使用</p>
        <p class="p2">6.1Cookie的使用</p>
        <p class="p2">Cookie是由网页服务器存放在您的访问设备上的文本文件。指定给您的Cookie是唯一的，它只能被将Cookie发布给您的域中的Web服务器读取。</p>
        <p class="p2">果老网使用Cookie来帮助您实现您的联机体验的个性化，使您在果老网及其关联方获得更轻松的访问体验。
          例如，Cookie会帮助您在后续访问果老网网站时调用您的信息，简化记录您填写个人信息（例如一键登录等）的流程；为您提供安全购物的偏好设置；帮助您优化对广告的选择与互动；保护您的数据安全等。</p>
        <p class="p2">您有权接受或拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie。如果选择拒绝Cookie，那么您可能无法完全体验所访问的果老网网站或某些服务的全部功能。</p>
        <p class="p2">6.2网络Beacon的使用</p>
        <p class="p2">果老网网页上常会包含一些电子图象（称为"单像素"GIF文件或"网络beacon"），它们可以帮助网站计算浏览网页的用户或访问某些cookie。果老网使用网络beacon的方式有：</p>
        <p class="p2">（1）果老网通过在果老网网站上使用网络beacon，计算用户访问数量，并通过访问cookie辨认注册用户。</p>
        <p class="p2">（2）果老网通过得到的cookie信息，可以在果老网网站提供个性化服务。</p>
        <p class="p3">7. 未成年人的个人信息保护</p>
        <p class="p2">我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用果老网服务前，建议您邀请您的父母或法定监护人（以下称“监护人”）仔细阅读本隐私权政策，并在事先取得您父母或监护人的同意的前提下使用我们的服务或向我们提供信息。</p>
        <p class="p2">对于经父母或监护人同意使用我们的产品或服务而收集的未成年人信息的，我们将严格按照国家相关法律法规的规定保护未成年人的个人信息。</p>
        <p class="p3">8. 通知和修订</p>
        <p class="p2">为给您提供更好的服务，果老网的业务将不时变化，本隐私权政策也将随之调整。果老网会通过在果老网网站、移动端上发出更新版本并提醒您相关内容的更新，也请您访问果老网以便及时了解最新的隐私政策。</p>
        <p class="p2">版本生效日期：2019年9月23日</p>
 </div>
    
  </div>
</template>

<script> 
export default {
  name: 'policy',
  components: {
      
  },
  data(){
      return{
          
      }
  },
  watch:{
      
  },
  methods:{
     
  }
}

</script>

<style scoped>
.policy{
  background-color: #fcfcfc;
}
  .policy-box{
      width: 800px;
      margin: 20px auto 20px;
  }
  .policy-box>p{
    margin-bottom: 20px;
    color: #333333;
  }
  .title{
    text-align: center;
    font-size: 20px;
    color: #333333;
    margin-bottom: 20px;
  }
  .p1{
      text-indent: 2em;
      font-weight: 600;
  }
  .p2{
    text-indent: 2em;
  }
  .p3{
    font-weight: 600;
  }
</style>
